import { useState } from "react";
import { Button, Container, Form, Header, Icon, Message } from "semantic-ui-react";
import authStore from "../stores/auth.store";

enum UserCreateStatus {
  NotRequested,
  UserAlreadyExists,
  EmailAlreadyExists,
  Successfull,
}
const NewUserModalComponent = () => {
  const [ username, setUsername ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ requestStatus, setRequestStatus ] = useState(UserCreateStatus.NotRequested)
  const [ role, setRole ] = useState("member");
  const [ organization, setOrganization ] = useState("");
  
  const orgs = authStore.user?.memberOf.filter((org) => {
    return org.userRole === 'admin' || org.userRole === 'owner';
  }).map((org) => {
    return {
      key: org.id,
      text: org.fullname,
      value: org.id,
    };
  });
  
  const createUser = () => {
    authStore.createUser({
        username,
        email,
        role,
        organization
    }).then(() => {
      setRequestStatus(UserCreateStatus.Successfull)
      setUsername("");
      setEmail("");
    }).catch((e) => {
      const msg = e.response.data.error.msg;
      if (msg === "Username already in use") {
        setRequestStatus(UserCreateStatus.UserAlreadyExists);
      } else if (msg === "Email already in use") {
        setRequestStatus(UserCreateStatus.EmailAlreadyExists)
      }
    });
  };
  
  return <Container>
    <Header>
      <Icon name="add user" />
      <Header.Content>
        Add user
      </Header.Content>
    </Header>
      <Form onSubmit={createUser}>
          <Form.Dropdown
            placeholder='Select an organisation'
            fluid
            selection
            label="Organisation"
            required
            options={orgs}
            onChange={(_e, data) => setOrganization(data.value as string)}
          />
          <Form.Dropdown
            defaultValue={role}
            fluid
            selection
            label="Role"
            required
            onChange={(_e, data) => setRole(data.value as string)}
            options={[
              /* TODO: load roles dynamically from server */
              { key: "admin", text: "Admin", value: "admin" },
              { key: "member", text: "User", value: "member" },
              { key: "readonly", text: "Read Only", value: "readonly" },
              { key: "disabled", text: "Disabled", value: "disabled" },
            ]}
          />
          <Form.Input
            label="Username"
            placeholder="johndoe"
            value={username}
            onChange={ (e) => setUsername(e.target.value) }
            required
            error={requestStatus === UserCreateStatus.UserAlreadyExists && "User already exists, try a different name"}
            fluid/>
          <Form.Input
            label="E-mail"
            placeholder="johndoe@example.com"
            onChange={ (e) => setEmail(e.target.value) }
            required
            type="email"
            value={email}
            error={requestStatus === UserCreateStatus.EmailAlreadyExists && "Email already exists, try a different one"}
            fluid/>
          {
            requestStatus === UserCreateStatus.Successfull && 
            <Message positive>
              User has been created and should receive an invitation by e-mail.
            </Message>
          }
          <Button positive fluid size="large">
            Create user
          </Button>

      </Form>
  </Container>;
};

export default NewUserModalComponent;
