import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

import apiService from "../services/api.service";
import { storage } from "../utils/storage";
import { isTokenExpired } from "../utils/jwt";
import { OrganizationUser, User } from "../types/user";

export interface Organization {
  userRole: string,
  id: string,
  shortname: string,
  fullname: string,
  members: OrganizationUser[],
}

// use mobx v6
class AuthStorePrivate {
  token?: string;
  user?: User;
  orgs: Organization[] = [];
  isLoggedIn: boolean = false;

  constructor() {
    makeObservable(this, {
      token: observable,
      user: observable,
      orgs: observable,
      setToken: action,
      setUser: action,
      setOrgs: action,
      isLoggedIn: observable,
      setLoggedIn: action,
      login: action,
      logout: action,
    });

    makePersistable(
      this,
      {
        name: "AuthStore",
        properties: ["token", "user", "isLoggedIn"],
        storage: window.localStorage,
      },
      { fireImmediately: true }
    );
  }

  async initUser() {
    const { data } = await apiService.get("/user/me");
    this.setUser(data);
    this.setLoggedIn(this.loginStatus());
  }

  async initOrgs() {
    const { data } = await apiService.get("/organizations");
    this.setOrgs(data);
  }

  setUser(user: User | undefined) {
    this.user = user;
  }

  updateUserInfo(user: Partial<User>) {
    // This is actually valid since strict: True implies that optional
    // properties are not set.
    this.user = { ...this.user, ...user } as User;
  }

  setOrgs(orgs: Organization[]) {
    this.orgs = orgs;
  }

  setLoggedIn(loggedIn: boolean) {
    this.isLoggedIn = loggedIn;
  }

  loginStatus(): boolean {
    return (
      this.token !== undefined && !isTokenExpired(this.token) && this.user !== undefined
    );
  }

  setToken(token: string | undefined) {
    this.token = token;
    if (token !== undefined) {
      storage.setItem("token", token);
    } else {
      storage.removeItem("token")
    }
    apiService.setAuthToken(token);
  }

  async login(email: string, password: string) {
    const { data } = await apiService.post("/user/login?mode=pass", {
      email,
      password,
    });
    this.setToken(data.token);
    await this.initUser();

    return this.loginStatus();
  }

  async requestPasswordReset(email: string) {
    await apiService.post("/user/password/reset/request", { email });
  }

  async resetPassword(params: ResetPasswordArgs) {
    await apiService.post(`/user/password/reset/confirm`, {
      token: params.token,
      password: params.password,
      confirm: params.confirm,
    });
  }
  
  async signUp(params: SignUpArgs)  {
    return await apiService.post("/user/register", params);
  }
  async createUser(params: CreateUserArgs) {
    return await apiService.post("/user/create", params);
  }

  logout() {
    this.setToken(undefined);
    this.setUser(undefined);
    this.isLoggedIn = false;

    storage.removeItem("token");

    apiService.clearAuthToken();
  }
}

export interface SignUpArgs {
  username: string,
  email: string,
  password: string,
  confirm: string,
}
export interface CreateUserArgs {
  username: string,
  email: string,
  organization: string,
  role: string,   
}

interface ResetPasswordArgs {
  token: string,
  password: string,
  confirm: string,
}

const authStore = new AuthStorePrivate();

export type AuthStore = InstanceType<typeof AuthStorePrivate>
export default authStore;
