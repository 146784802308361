import { MouseEvent, useEffect, useState } from "react";
import {
  Icon,
  Dropdown,
  Button,
  Form,
  Modal,
  Search,
  StrictSearchResultProps,
  StrictSearchProps,
} from "semantic-ui-react";

import { observer } from "mobx-react";

import { useDebounce } from "usehooks-ts";

import apiClient from "../services/api.service";
import { Organization } from "../stores/auth.store";
import { UserSearchResult } from "../types/user";

interface OrgInviteModalComponentProps {
  open: boolean,
  onClose: () => void,
  org: Organization,
}


const OrgInviteModalComponent = observer((props: OrgInviteModalComponentProps) => {
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState("user");

  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<UserSearchResult[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 100);

  const resultRenderer = (d: StrictSearchResultProps & { name?: string }) => (
    <>
      <Icon name="user" />
      {d.name} <i>&#060;{d.title}&#062;</i>
    </>
  );

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      apiClient
        .get("/users/search", {
          params: {
            q: debouncedSearchTerm,
          },
        })
        .then((res) => {
          setResults(
            res.data.map((user: UserSearchResult) => {
              return {
                title: user.email,
                name: user.name,
              };
            })
          );
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [debouncedSearchTerm]);

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Modal.Header>Invite User to {props.org.fullname}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Email</label>
            <Search
              loading={loading}
              fluid
              placeholder="Search for a user using their email"
              minCharacters={2}
              selectFirstResult={true}
              onSearchChange={(_e: MouseEvent<HTMLElement>, data: StrictSearchProps) => {
                setLoading(true);

                if (!data.value || data.value.length === 0) {
                  setResults([]);
                  setLoading(false);
                } else if (data.value.length >= 2) {
                  setSearchTerm(data.value);
                }
              }}
              onResultSelect={(_e, data) => {
                setEmail(data.result.title);
              }}
              resultRenderer={resultRenderer}
              results={results}
            ></Search>
          </Form.Field>

          <Form.Field>
            <label>Role</label>
            {/* TODO: load roles dynamically from server */}
            <Dropdown
              placeholder="Role"
              fluid
              selection
              options={[
                { key: "admin", text: "Admin", value: "admin" },
                { key: "user", text: "User", value: "user" },
                { key: "readonly", text: "Read Only", value: "readonly" },
                { key: "disabled", text: "Disabled", value: "disabled" },
              ]}
              defaultValue={role}
              onChange={(_e, data) => setRole(data.value as string)}
            />
          </Form.Field>

          <Button
            primary
            onClick={async () => {
              try {
                await apiClient.post(
                  "/organizations/" + props.org.id + "/invite",
                  {
                    email: email,
                    role: role,
                  }
                );
                props.onClose();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            Invite
          </Button>
          <Button onClick={props.onClose}>Cancel</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
});

export default OrgInviteModalComponent;
