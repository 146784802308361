import { observer } from "mobx-react";
import LoggedOutLayout from "../layouts/LoggedOutLayout";
import RequestPasswordResetForm from "../components/RequestPasswordResetForm";

const ResetPasswordPage = observer(() => {
  return (
    <LoggedOutLayout title="Reset your Password">
      <RequestPasswordResetForm />
    {
      // <Message>
        // Want to login? <Link to="/login"> Click here </Link>
      // </Message>
}
    </LoggedOutLayout>
  )
  
});

export default ResetPasswordPage;
