import { useEffect } from "react";
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import { observer } from "mobx-react";

import { Container, Menu } from "semantic-ui-react";
import { AuthStore } from "./stores/auth.store";

interface AppProps {
  authStore: AuthStore,
}
const App = observer(({ authStore }: AppProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (authStore.user === null) {
      navigate("/login");
    }
  }, [authStore.user, navigate]);

  const items = [
    {
      key: "dash",
      name: "Dashboard",
      icon: "dashboard",
      as: NavLink,
      to: "/",
    },
    {
      key: "beacons",
      name: "Beacons",
      icon: "radio",
      as: NavLink,
      to: "/beacons",
    },
    { key: "events", name: "Events", icon: "bell" },
    {
      key: "users",
      name: "Users",
      icon: "users",
      as: NavLink,
      to: "/users",
    },
    { key: "settings", name: "Settings", icon: "settings" },
    {
      key: "logout",
      name: authStore.user?.firstName
        ? `${authStore.user.firstName} ${authStore.user.lastName}`
        : authStore.user?.username || "",
      icon: "log out",
      position: "right",
      onClick: () => {
        authStore.logout();
        navigate("/login");
      },
    },
  ];

  return (
    <div className="App">
      <Container>
        <Menu items={items} />
        <Outlet />
      </Container>
    </div>
  );
});

export default App;
