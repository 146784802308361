import { createRef, Fragment, useEffect, useState } from "react"
import { Ref } from "semantic-ui-react";
import PasswordInput from "./PasswordInput"


interface PasswordWithConfirmProps {
  onChange: (newPassword: string) => void
  value?: string,
}

const PasswordWithConfirm = ({ onChange, value }: PasswordWithConfirmProps) => {
  const [ password, setPassword ] = useState("");
  const [ passwordConfirmed, setPasswordConfirmed ] = useState("");
  
  const confirmInput = createRef<HTMLElement>();
  
  
  useEffect(() => {
    if (password === "" && passwordConfirmed === "") {
      return;
    }
    const isValid = password.trim() === passwordConfirmed.trim();

    confirmInput.current?.querySelector("input")?.setCustomValidity(isValid ? "" : "Passwords have to be the same");
    
    if (isValid) {
      onChange(password.trim());
    }
  }, [password, passwordConfirmed, onChange, confirmInput]);
  
  useEffect(() => {
    if (value !== undefined) {
      setPassword(value);
      setPasswordConfirmed(value);
    }
  }, [value])
  
  return (
    <Fragment>
      <PasswordInput
        label="Password"
        minLength="10"
        required
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <Ref innerRef={confirmInput}>
        <PasswordInput
          label="Password (confirm)"
          minLength="10"
          required
          value={passwordConfirmed}
          onChange={(e) => {
            setPasswordConfirmed(e.target.value);
          }}
        />
      </Ref>
    </Fragment>
  )
}


export default PasswordWithConfirm