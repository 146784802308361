import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { Container } from "semantic-ui-react";

import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import apiService from "../services/api.service";
import { Beacon } from "../types/beacon";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,

  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const BeaconMap = observer(() => {
  const [beacons, setBeacons] = useState([]);

  useEffect(() => {
    apiService.get("/beacons").then((res) => {
      setBeacons(res.data);
    });
  }, []);

  return (
    <Container>
      <MapContainer
        center={[52.5, 13.4]}
        zoom={10}
        scrollWheelZoom={true}
        className="leaflet-container"
        style={{ height: "500px", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.osmand.net/hd/{z}/{x}/{y}.png"
        />
        {beacons.map((beacon: Beacon) => (
          <Marker
            key={beacon.id}
            position={[beacon.location[1], beacon.location[0]]}
          >
            <Popup>
              <b>Proximity UUID:</b> {beacon.proximityUuid}
              <br />
              <b>Major / Minor:</b> {beacon.major} / {beacon.minor}
              <br />
              <b>Name:</b> {beacon.name}
              <br />
              <b>Location:</b> {beacon.location[0].toFixed(6)}{" "}
              {beacon.location[1].toFixed(6)}
              <br />
              <b>Owner:</b> {beacon.owner}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </Container>
  );
});

export default BeaconMap;
