import { observer } from "mobx-react";
import { useState } from "react";
import { Container, Grid, Header, Menu, MenuItemProps } from "semantic-ui-react";

import BeaconList from "../components/BeaconList";
import BeaconMap from "../components/BeaconMap";

const BeaconsPage = observer(() => {
  const [activeItem, setActiveItem] = useState("beacon-list");

  return (
    <Container>
      <Header as="h1">Beacons</Header>
      <Grid>
        <Grid.Column width={4}>
          <Menu secondary vertical>
            <Menu.Item
              name="Beacon List"
              active={activeItem === "beacon-list"}
              onClick={() => setActiveItem("beacon-list")}
            />
            <Menu.Item
              name="Beacon Map"
              active={activeItem === "beacon-map"}
              onClick={() => setActiveItem("beacon-map")}
            />
            <Menu.Item
              name="Beacon Types"
              active={activeItem === "beacon-types"}
              onClick={() => setActiveItem("beacon-types")}
            />
          </Menu>
        </Grid.Column>
        <Grid.Column width={11}>
          {activeItem === "beacon-list" && <BeaconList />}
          {activeItem === "beacon-types" && <p>Beacon Types</p>}
          {activeItem === "beacon-map" && <BeaconMap />}
        </Grid.Column>
      </Grid>
    </Container>
  );
});

export default BeaconsPage;
