import decode, { JwtPayload } from "jwt-decode";

interface Payload extends JwtPayload {
  allowPasswordChange?: boolean,
}
export const getTokenExpirationDate = (token: string) => {
  const decoded = decode<Payload>(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);
  return date;
};

export const isTokenExpired = (token: string) => {
  const expirationDate = getTokenExpirationDate(token);

  if (!expirationDate) {
    return false;
  }

  return expirationDate < new Date();
};

export const checkPasswordChangePermission = (token: string) => {
  const decoded = decode<Payload>(token);
  return decoded.allowPasswordChange || false;
};
