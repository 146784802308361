import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Button, Container, Form, Input, Modal } from "semantic-ui-react";
import { Beacon } from "../types/beacon";

interface EditBeaconProps {
  open: boolean,
  editBeacon: Beacon,
  onClose: () => void,
}

const EditBeaconComponent = observer((props: EditBeaconProps) => {
    const [open, setOpen] = useState(props.open);
    const [beacon, setBeacon] = useState(props.editBeacon);

    const handleClose = () => {
      setOpen(false);
      props.onClose();
    };

    useEffect(() => {
      setOpen(props.open);
      setBeacon(props.editBeacon);
    }, [props.open, props.editBeacon]);

    return (
      <Container>
        <Modal open={open} onClose={handleClose}>
          <Modal.Header>Edit Beacon</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Beacon Name</label>
                <Input
                  placeholder="Beacon Name"
                  value={beacon.name}
                  onChange={(e) => {
                    setBeacon({ ...beacon, name: e.target.value });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Beacon Type</label>
                <Input placeholder="Beacon Type" />
              </Form.Field>
              <Form.Field>
                <label>Beacon Description</label>
                <Input placeholder="Beacon Description" />
              </Form.Field>
              <Form.Field>
                <label>Beacon Location</label>
                <Input placeholder="Beacon Location" />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // TODO: save edited beacon data
                handleClose();
              }}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
);

export default EditBeaconComponent;
