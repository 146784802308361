import axios from "axios";

import authStore from "../stores/auth.store";

const API_URL = process.env.BACKEND_URL || "http://localhost:8080/";

class ApiService {
  apiClient;

  constructor() {
    this.apiClient = axios.create({
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.apiClient.interceptors.request.use((config) => {
      return config;
    });

    this.apiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { response } = error;
        if (response && response.status === 401) {
          // redirect to login page
        }
        return Promise.reject(error);
      }
    );

    this.apiClient.mergePatch = (url, data, opts) => {
      return this.apiClient.patch(
        url,
        data,
        Object.assign(
          {},
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          },
          opts
        )
      );
    };
  }

  setAuthToken(token) {
    this.apiClient.defaults.headers.Authorization = `Bearer ${token}`;
    this.apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  clearAuthToken() {
    this.setAuthToken(undefined);
  }

  checkUnauthorized(error) {
    const { response } = error;

    if (response && response.status === 401) {
      authStore.logout();
    } else {
      throw error;
    }

    return Promise.reject(error);
  }

  get(url, options) {
    return this.apiClient.get(url, options).catch(this.checkUnauthorized);
  }

  post(url, body, options) {
    return this.apiClient
      .post(url, body, options)
      .catch(this.checkUnauthorized);
  }

  delete(url, options) {
    return this.apiClient.delete(url, options).catch(this.checkUnauthorized);
  }

  postPasswordChange(url, body, token, options) {
    return this.apiClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    });
  }

  patch(url, body, options) {
    return this.apiClient
      .patch(url, body, options)
      .catch(this.checkUnauthorized);
  }

  put(url, body, options) {
    return this.apiClient.put(url, body, options).catch(this.checkUnauthorized);
  }

  mergePatch = (url, body, options) => {
    return this.apiClient
      .patch(
        url,
        body,
        Object.assign(
          {},
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          },
          options
        )
      )
      .catch(this.checkUnauthorized);
  };

  upload(url, body, options) {
    return this.apiClient
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...options,
      })
      .catch(this.checkUnauthorized);
  }

  download(url, options) {
    return this.apiClient
      .get(url, {
        responseType: "blob",
        ...options,
      })
      .catch(this.checkUnauthorized);
  }

  downloadFile(url, fileName, options) {
    return this.apiClient
      .get(url, {
        responseType: "blob",
        ...options,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(this.checkUnauthorized);
  }
}

const apiService = new ApiService();
export default apiService;
