import { PropsWithChildren } from "react";
import {
  Grid,
  Header,
  Image,
} from "semantic-ui-react";
import { observer } from "mobx-react";

import logo from "../logo.svg";

interface Props {
  title: String,
}

const LoggedOutLayout = observer((props: PropsWithChildren<Props>) => {
  return (
    <Grid centered style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src={logo} />
          { props.title }
        </Header>
        { props.children }
      </Grid.Column>
    </Grid>
  );
});

export default LoggedOutLayout;
