import { useEffect, useState } from "react";
import {
  Container,
  Header,
  Icon,
  Button,
  Image,
  Form,
  Divider,
  Input,
  Grid,
  Dimmer,
} from "semantic-ui-react";

import authStore from "../stores/auth.store";

import apiClient from "../services/api.service";

import { checkPasswordChangePermission } from "../utils/jwt";
import PasswordInput from "./PasswordInput";

const AccountComponent = () => {
  const [firstName, setFirstName] = useState(authStore.user?.firstName || "");
  const [lastName, setLastName] = useState(authStore.user?.lastName || "");
  const [email, setEmail] = useState(authStore.user?.email || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passwordChangePermission, setPasswordChangePermission] =
    useState(false);
  const [passwordChangeToken, setPasswordChangeToken] = useState("");
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [profileDimmed, setProfileDimmed] = useState(false);

  const handleSave = () => {
    apiClient
      .patch("/user/" + authStore.user?.id, {
        firstName,
        lastName,
        email,
      })
      .then((response) => {
        authStore.updateUserInfo(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setFirstName(authStore.user?.firstName || "");
    setLastName(authStore.user?.lastName || "");
    setEmail(authStore.user?.email || "");
  }, []);

  const handleCancel = () => {
    setFirstName(authStore.user?.firstName || "");
    setLastName(authStore.user?.lastName || "");
    setEmail(authStore.user?.email || "");

    console.log("Cancel");
  };

  const oldPasswordConfirm = () => {
    setPasswordLoading(true);
    apiClient
      .post("/user/password/confirm", {
        password: oldPassword,
      })
      .then((response) => {
        if (response.data) {
          setPasswordChangePermission(
            checkPasswordChangePermission(response.data.token)
          );
          setPasswordChangeToken(response.data.token);
          setPasswordLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        if (
          error.response.status === 401 &&
          error.response.data.error === "Invalid password"
        ) {
          // TODO: notify user of invalid password
          console.log("password invalid");
          setPasswordLoading(false);
        }
      });
  };

  const handlePasswordSubmit = () => {
    apiClient
      .postPasswordChange(
        "/user/password/change",
        {
          password,
          confirmPassword,
        },
        passwordChangeToken
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePasswordCancel = () => {
    setPassword("");
    setConfirmPassword("");
    setOldPassword("");
    setPasswordChangePermission(false);
  };

  return (
    <Container>
      <Grid>
        <Grid.Column width={12}>
          <Header as="h2">
            <Icon name="settings" />
            <Header.Content>
              Account Settings
              <Header.Subheader>
                Manage your personal information
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
        {/* show & edit profile picture */}
        <Grid.Column width={4}>
          <Dimmer.Dimmable
            as={Image}
            dimmed={profileDimmed}
            dimmer={{
              active: profileDimmed,
              content: (
                <Button>
                  Edit
                  <Icon name="caret down" />
                </Button>
              ),
            }}
            onMouseEnter={() => setProfileDimmed(true)}
            onMouseLeave={() => setProfileDimmed(false)}
            size="medium"
            src="https://react.semantic-ui.com/images/wireframe/square-image.png"
            alt="profile"
            circular
          />
        </Grid.Column>
      </Grid>

      <Divider section />

      <Header as="h3">
        <Icon name="user" />
        <Header.Content>
          Personal Information
          <Header.Subheader>Update your personal information</Header.Subheader>
        </Header.Content>
      </Header>

      <Divider hidden />

      <Form>
        <Form.Field>
          <label>First Name</label>
          <Input
            placeholder="John"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <Input
            placeholder="Doe"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Input
            placeholder="john.doe@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Field>
        <Button type="submit" onClick={handleSave}>
          Submit
        </Button>
        <Button type="reset" onClick={handleCancel}>
          Cancel
        </Button>
      </Form>

      <Divider section />

      {/* change password modal */}
      <Header as="h3">
        <Icon name="key" />
        <Header.Content>
          Change Password
          <Header.Subheader>
            Please confirm with your current password
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Divider hidden />

      {passwordChangePermission ? (
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <PasswordInput
                hideLock={true}
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <PasswordInput
                hideLock={true}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Field>
          </Form.Group>
          <Button type="submit" onClick={handlePasswordSubmit}>
            Submit
          </Button>
          <Button type="reset" onClick={handlePasswordCancel}>
            Cancel
          </Button>
        </Form>
      ) : (
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <PasswordInput
                hideLock={true}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                loading={passwordLoading}
              />
            </Form.Field>
            <Button type="submit" onClick={oldPasswordConfirm}>
              Check
            </Button>
          </Form.Group>
        </Form>
      )}
    </Container>
  );
};

export default AccountComponent;
