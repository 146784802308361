import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Container,
  Header,
  Icon,
  Label,
  Popup,
  Table,
} from "semantic-ui-react";
import { formatDistance } from "date-fns";

import authStore from "../stores/auth.store";

import apiService from "../services/api.service";
import EditBeaconComponent from "./EditBeaconComponent";
import SmallMapComponent from "./SmallMapComponent";
import { Beacon } from "../types/beacon";

const BeaconList = observer(() => {
  const [activeRow, setActiveRow] = useState("");
  const [beacons, setBeacons] = useState([]);
  const [isBeaconEditModalOpen, setIsBeaconEditModalOpen] = useState(false);
  const [selectedBeacon, setSelectedBeacon] = useState<Beacon | undefined>();

  useEffect(() => {
    apiService.get("/beacons").then((res) => {
      setBeacons(res.data);
    });
    authStore.initOrgs();
  }, []);

  const getOrganizationNameById = (orgId: string) => {
    const org = authStore.orgs.find((org) => org.id === orgId);
    return org
      ? { short: org.shortname, full: org.fullname }
      : {
          short: "Unknown",
          full: "Unknown",
        };
  };

  return (
    <Container>
      <Header as="h1">All Beacons</Header>

      <Table selectable sortable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Last discovered</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>Major</Table.HeaderCell>
            <Table.HeaderCell>Minor</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {beacons.map((beacon: Beacon) => (
            <Table.Row
              active={activeRow === beacon.id}
              key={beacon.id}
              onClick={() => setActiveRow(beacon.id)}
            >
              <Table.Cell collapsing>
                <strong>{beacon.name}</strong>
              </Table.Cell>
              <Table.Cell collapsing>
                <Label color="green">Active</Label>
              </Table.Cell>
              <Table.Cell collapsing>
                {formatDistance(Date.parse(beacon.lastDiscovered), new Date(), {
                  addSuffix: true,
                })}
              </Table.Cell>
              <Table.Cell collapsing textAlign="center">
                <Popup
                  flowing
                  hoverable
                  basic
                  trigger={<Icon name="map" color="grey" />}
                  style={{ padding: 0, borderRadius: "10px" }}
                >
                  <SmallMapComponent location={beacon.location} />
                </Popup>
              </Table.Cell>
              <Table.Cell collapsing>
                <Label>{beacon.major}</Label>
              </Table.Cell>
              <Table.Cell collapsing>
                <Label>{beacon.minor}</Label>
              </Table.Cell>
              <Table.Cell collapsing>
                <Popup
                  trigger={
                    <Label color="blue">
                      {getOrganizationNameById(
                        beacon.owner
                      ).short.toUpperCase()}
                    </Label>
                  }
                  content={getOrganizationNameById(beacon.owner).full}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                {/* TODO: add edit/delete modal -> map picker for location */}
                <Button
                  icon="edit"
                  onClick={() => {
                    setSelectedBeacon(beacon);
                    setIsBeaconEditModalOpen(true);
                  }}
                  size="mini"
                />
                <Icon name="trash" color="grey"></Icon>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {
        selectedBeacon &&
        <EditBeaconComponent
          open={isBeaconEditModalOpen}
          editBeacon={selectedBeacon}
          onClose={() => setIsBeaconEditModalOpen(false)}
        />
      }
    </Container>
  );
});

export default BeaconList;
