import { redirect } from "react-router-dom";
import { isHydrated } from "mobx-persist-store";

import authStore from "../stores/auth.store";

import apiService from "../services/api.service";

const loader = async () => {
  while (!isHydrated(authStore)) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  console.log("authStore.isLoggedIn", authStore.isLoggedIn);

  apiService.setAuthToken(authStore.token);
  if (!authStore.isLoggedIn) {
    return redirect("/login");
  }
};

export default loader;
