import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Message } from "semantic-ui-react";
import LoginForm from "../components/LoginForm";
import LoggedOutLayout from "../layouts/LoggedOutLayout"

const LoginPage = observer(() => {
  return (
    <LoggedOutLayout title="Log-in to your account">
      <LoginForm/>
      <Message textAlign="center">
        New to us? <Link to="/sign-up">Sign Up</Link>
      </Message>
    </LoggedOutLayout>
  )
})

export default LoginPage;