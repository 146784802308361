import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "semantic-ui-less/semantic.less";

import App from "./App";
import ErrorPage from "./pages/ErrorPage";
import UsersPage from "./pages/UsersPage";
import BeaconsPage from "./pages/BeaconsPage";
import LoginPage from "./pages/LoginPage";
import RequestPasswordResetPage from "./pages/RequestPasswordResetPage"
import ResetPasswordPage from "./pages/ResetPasswordPage";



import userLoader from "./loaders/user";

import authStore from "./stores/auth.store";

import reportWebVitals from "./reportWebVitals";
import SignUpPage from "./pages/SignUpPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App authStore={authStore} />,
    errorElement: <ErrorPage />,
    loader: userLoader,
    children: [
      {
        path: "/users",
        element: <UsersPage />,
      },
      {
        path: "/beacons",
        element: <BeaconsPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/request-password-reset",
    element: <RequestPasswordResetPage />
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />
  },
  {
    path: "/sign-up",
    element: <SignUpPage />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
