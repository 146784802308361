import { Component } from "react";
import { observer } from "mobx-react";
import { Container, Divider, Header, Icon } from "semantic-ui-react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,

  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const SmallMapComponent = observer((props: { location: [number, number] }) => {
  return (
    <MapContainer
      center={[props.location[1], props.location[0]]}
      zoom={12}
      className="leaflet-container"
      style={{ height: "300px", width: "300px", borderRadius: "10px" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://tile.osmand.net/hd/{z}/{x}/{y}.png"
      />
      <Marker position={[props.location[1], props.location[0]]}></Marker>
    </MapContainer>
  );
});

export default SmallMapComponent;
