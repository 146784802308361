import { useState } from "react";
import { Form, FormInputProps, Icon } from "semantic-ui-react";

const PasswordInput = (props: FormInputProps & { hideLock?: boolean } ) => {
  const { hideLock, ...innerProps } = props;

  const [hidePassword, setHidePassword] = useState(true);

  const showPassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <Form.Input
      fluid
      label="Password"
      placeholder="horse battery staple"
      icon
      iconPosition={ hideLock ? undefined : "left" }
      type={ hidePassword ? "password" : "text" }
      {...innerProps}
    >
      { !hideLock && <Icon name="lock" /> }
      <input />
      <Icon
        name={ hidePassword ? "eye" : "eye slash" }
        style={{left: "auto", right: "1px"}}
        aria-hidden="false"
        aria-label={ hidePassword ? "Show password" : "Hide password" }
        onClick={showPassword}
        link />
    </Form.Input>
  )
}

export default PasswordInput;
