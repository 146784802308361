import { Fragment, useEffect, useState } from "react";
import {
  Container,
  Header,
  Icon,
  Dropdown,
  Button,
  Table,
  Label,
  Loader,
} from "semantic-ui-react";

import { observer } from "mobx-react";

import authStore, { Organization } from "../stores/auth.store";

import apiClient from "../services/api.service";

import OrgInviteModalComponent from "./OrgInviteModalComponent";
import { User } from "../types/user";

const EditUsersComponent = observer(() => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
  const [orgToInvite, setOrgToInvite] = useState<Organization | undefined>();

  useEffect(() => {
    authStore.initUser();
    authStore.initOrgs();
  }, []);

  const renderItemCaret = (rowId: string) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <Icon name="caret down" />;
    } else {
      return <Icon name="caret right" />;
    }
  };

  const toggleExpandedRow = (rowId: string) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    setExpandedRows(newExpandedRows);
  };

  const renderRoleDropdown = (
    current: string,
    permissionChangeAllowed: boolean,
    roleName: string,
    orgId: string,
    userId: string
  ) => {
    if (permissionChangeAllowed) {
      return (
        <Dropdown
          floating
          labeled
          defaultValue={current}
          options={[
            {
              key: "owner",
              text: "Owner",
              value: "owner",
              label: { color: "violet", empty: true, circular: true },
            },
            {
              key: "admin",
              text: "Admin",
              value: "admin",
              label: { color: "purple", empty: true, circular: true },
            },
            {
              key: "member",
              text: "User",
              value: "member",
              label: { color: "blue", empty: true, circular: true },
            },
            {
              key: "readonly",
              text: "Read Only",
              value: "readonly",
              label: { color: "orange", empty: true, circular: true },
            },
            {
              key: "disabled",
              text: "Disabled",
              value: "disabled",
              label: { color: "grey", empty: true, circular: true },
            },
          ]}
          onChange={(_e, data) => {
            console.log(data.value);
            apiClient
              .put("/organization/" + orgId + "/member/" + userId + "/role", {
                role: data.value,
              })
              .then((res) => {
                console.log(res.data);
                authStore.initOrgs();
              });
          }}
        />
      );
    } else {
      return roleName;
    }
  };

  const renderRole = (
    role: string,
    permissionChangeAllowed: boolean,
    meta: { org: Organization, user: User } | {},
  ) => {
    let orgId;
    let userId;
    if (!permissionChangeAllowed || !('org' in meta)) {
      orgId = "0";
      userId = "0";
    } else {
      orgId = meta.org.id;
      userId = meta.user.id
    }
    switch (role) {
      case "admin":
        return (
          <Label style={{ width: "80px", textAlign: "center" }} color="purple">
            {renderRoleDropdown(
              role,
              permissionChangeAllowed,
              "Admin",
              orgId,
              userId,
            )}
          </Label>
        );
      case "member":
        return (
          <Label style={{ width: "80px", textAlign: "center" }} color="blue">
            {renderRoleDropdown(
              role,
              permissionChangeAllowed,
              "User",
              orgId,
              userId,
            )}
          </Label>
        );
      case "owner":
        return (
          <Label style={{ width: "80px", textAlign: "center" }} color="violet">
            {renderRoleDropdown(
              role,
              permissionChangeAllowed,
              "Owner",
              orgId,
              userId,
            )}
          </Label>
        );
      case "readonly":
        return (
          <Label style={{ width: "90px", textAlign: "center" }} color="orange">
            {renderRoleDropdown(
              role,
              permissionChangeAllowed,
              "Read Only",
              orgId,
              userId,
            )}
          </Label>
        );
      case "disabled":
        return (
          <Label style={{ width: "80px", textAlign: "center" }} color="grey">
            {renderRoleDropdown(
              role,
              permissionChangeAllowed,
              "Disabled",
              orgId,
              userId,
            )}
          </Label>
        );
      default:
        return (
          <Label style={{ width: "80px", textAlign: "center" }} color="blue">
            {renderRoleDropdown(
              "user",
              permissionChangeAllowed,
              "User",
              orgId,
              userId,
            )}
          </Label>
        );
    }
  };

  return (
    <Container>
      <Header as="h3">
        <Icon name="group" />
        <Header.Content>
          Edit users
        </Header.Content>
      </Header>
      <Table selectable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Members</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {authStore.user?.memberOf.map((org) => (
            <Fragment key={org.id}>
              <Table.Row onClick={() => toggleExpandedRow(org.id)}>
                <Table.Cell collapsing>
                  {renderItemCaret(org.id)}
                  {authStore.orgs.filter(
                    (el) => org.id === el.id
                  )[0] &&
                    authStore.orgs.filter(
                      (el) => org.id === el.id
                    )[0].members.length > 0 && (
                      <Label circular>
                        {
                          authStore.orgs.filter(
                            (el) => org.id === el.id
                          )[0].members.length
                        }
                      </Label>
                    )}
                </Table.Cell>

                <Table.Cell>
                  {org.userRole === "disabled" ? (
                    <span style={{ textDecoration: "line-through" }}>
                      {org.fullname}
                    </span>
                  ) : (
                    org.fullname
                  )}
                </Table.Cell>
                <Table.Cell collapsing>
                  {renderRole(org.userRole, false, {})}
                </Table.Cell>
                <Table.Cell>
                  {/* invite user if owner, admin */}
                  {(org.userRole === "owner" || org.userRole === "admin") && (
                    <>
                      <Button
                        size="mini"
                        data-tooltip="Invite User"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOrgToInvite(org);
                          setIsInviteModalOpen(false);
                          setIsInviteModalOpen(true);
                        }}
                      >
                        <Icon name="add user" />
                      </Button>
                    </>
                  )}

                  {/* leave button if not owner*/}
                  {org.userRole !== "owner" && (
                    <Button
                      color="red"
                      size="mini"
                      data-tooltip="Leave Organization"
                      onClick={() => {
                        apiClient
                          .delete(`/organizations/${org.id}/members/me`)
                          .then((_res) => {
                            authStore.initUser();
                            authStore.initOrgs();
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    >
                      <Icon name="remove user" />
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
              {expandedRows.includes(org.id) && (
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Role</Table.HeaderCell>
                          {(org.userRole === "owner" ||
                            org.userRole === "admin") && (
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                          )}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {!authStore.orgs && (
                          <Table.Row selectable={false}>
                            <Table.Cell colSpan={4}>
                              <Loader active inline="centered" />
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {authStore.orgs &&
                          authStore.orgs
                            .filter((el) => org.id === el.id)[0]
                            .members.map((user) => (
                              <Table.Row key={user.id}>
                                <Table.Cell>
                                  {/* show name striked through if user role is disabled */}
                                  {user.role === "disabled" ? (
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {user.firstName} {user.lastName} (
                                      {user.username})
                                    </span>
                                  ) : (
                                    <>
                                      {user.firstName} {user.lastName} (
                                      {user.username})
                                    </>
                                  )}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                  {renderRole(
                                    user.role,
                                    (org.userRole === "owner" ||
                                      org.userRole === "admin") &&
                                      user.id !== authStore.user?.id,
                                    { user, org }
                                  )}
                                </Table.Cell>

                                {/* remove user if owner, admin */}
                                {(org.userRole === "owner" ||
                                  org.userRole === "admin") && (
                                  <Table.Cell>
                                    <Button
                                      color="red"
                                      size="mini"
                                      data-tooltip="Remove User"
                                      onClick={() => {
                                        apiClient
                                          .delete(
                                            `/organizations/${org.id}/members/${user.id}`
                                          )
                                          .then((_res) => {
                                            authStore.initOrgs();
                                          });
                                      }}
                                    >
                                      <Icon name="remove user" />
                                    </Button>
                                  </Table.Cell>
                                )}
                              </Table.Row>
                            ))}
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
              )}
            </Fragment>
          ))}
        </Table.Body>
      </Table>
      { orgToInvite &&
        <OrgInviteModalComponent
          open={isInviteModalOpen}
          org={orgToInvite}
          onClose={() => {
            setIsInviteModalOpen(false);
            authStore.initOrgs();
          }}
        />
      }
    </Container>
  );
});

export default EditUsersComponent;
