import { observer } from "mobx-react-lite";
import { Container, Divider, Header, Icon } from "semantic-ui-react";
import NewUserModalComponent from "./NewUserModalComponent";
import EditUsersComponent from "./OrganizationsComponent";

const UsersComponent = observer(() => {
  return (
    <Container>
      <Header as="h2">
        <Icon name="user" />
        <Header.Content>
          Users
          <Header.Subheader>Manage users</Header.Subheader>
        </Header.Content>
      </Header>
      <Divider section />
      <NewUserModalComponent />
      <Divider section />
      <EditUsersComponent/>
    </Container>
  );
});

export default UsersComponent;
