import { Button, Form, Grid, Segment } from "semantic-ui-react";
import authStore from "../stores/auth.store";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import PasswordInput from "./PasswordInput";

enum LoginError {
  General,
  WrongPassword,  
}

const LoginForm = () => {
  const [ loginError, setLoginError ] = useState<LoginError | null>(null);
  const [ email, setEMail ] = useState("");
  const [ password, setPassword ] = useState("");
  
  const navigate = useNavigate();

  const login = () => {
    authStore
      .login(email, password)
      .then((a) => (a ? navigate("/") : null))
      .catch((error) => {
        if (error.response.data.msg === "Invalid credentials") {
          setLoginError(LoginError.WrongPassword)
        } else {
          setLoginError(LoginError.General)
        }
      })
    };
  
  
  
  return <Form size="large" onSubmit={login}>
    <Segment>
      
      { /* The ref is needed to make sure that we can manually trigger validation
           of the E-Mail when a Password Reset is performed. This currently triggers 
           a deprecated warning, but this has to fixed by semantic ui See:
           https://github.com/Semantic-Org/Semantic-UI-React/issues/3819 */ }
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        type="email"
        label="E-mail address"
        placeholder="user@example.com"
        error={ loginError === LoginError.General && "User does not exist, try again or sign up below"}
        required
        onChange={(e) => setEMail(e.target.value)}
      />
      <PasswordInput
        required
        error={loginError === LoginError.WrongPassword && "Invalid Password, try again or reset it below"}
        onChange={(e) => setPassword(e.target.value)}
        />

      <Grid centered columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Button color="teal" fluid size="large" type="submit">
              Login
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Link to="/request-password-reset">
            Reset your password
          </Link>
        </Grid.Row>
      </Grid>
    </Segment>
  </Form>
};

export default LoginForm;
