import { useState } from "react";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import authStore from "../stores/auth.store";

enum RequestStatus {
  NotRequested,
  Sending,
  Sent,
  UserNotFound,
}
const ResetPasswordForm = () => {
  const [ email, setEMail ] = useState("");
  const [ requestStatus, setRequestStatus ] = useState(RequestStatus.NotRequested)
  const requestPasswordReset = () => {
    authStore.requestPasswordReset(email)
      // TODO: Needs to differentiate between different error types (user does not exist...),
      // but currently the backend doesn't support this anyway
      .then(() => setRequestStatus(RequestStatus.Sent))
      .catch(() => setRequestStatus(RequestStatus.UserNotFound));
  }
  return (
    <Form onSubmit={requestPasswordReset} size="large">
      <Segment>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            type="email"
            label="E-mail address"
            placeholder="user@example.com"
            required
            onChange={ (e) => setEMail(e.target.value) }
            error={ requestStatus === RequestStatus.UserNotFound && "User does not exist, make sure that you spelled everything correctly"}
          />
          { requestStatus !== RequestStatus.Sent &&
            <Button
              color="teal"
              fluid
              size="large"
              type="submit">
              Send reset e-mail
            </Button>
          }
          { requestStatus === RequestStatus.Sent &&
            <Message positive>
              An E-mail has been sent to you with a link to
              reset your password. Make sure to check your spam folder.
            </Message>
          }
  </Segment>
    </Form>
  ) 
}

export default ResetPasswordForm;
