import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import authStore from "../stores/auth.store";
import PasswordWithConfirm from "./PasswordWithConfirm";

enum ResetStatus {
  NotRequested,
  InvalidToken,
  ResetSuccessfull,
}
const ResetSetPasswordForm = () => {
  const [ newPassword, setNewPassword ] = useState("");
  const [ searchParams ] = useSearchParams();
  const [ resetStatus, setResetStatus ] = useState(ResetStatus.NotRequested);
  
  const resetPassword = () => {
    const token = searchParams.get("token");
    if (token === null) {
      setResetStatus(ResetStatus.InvalidToken);
      return;
    }
   
    authStore.resetPassword({
      password: newPassword,
      confirm: newPassword,
      token,
    }).then(() => setResetStatus(ResetStatus.ResetSuccessfull))
      .catch(() => setResetStatus(ResetStatus.InvalidToken)) ;
  };
  
  return (
    <Form onSubmit={resetPassword}>
      <Segment>
        <PasswordWithConfirm onChange={ (password) => setNewPassword(password) } />
        { resetStatus === ResetStatus.NotRequested &&
          <Button
            positive
            fluid
            size="large"
            type="submit">
            Set new Password
          </Button>
        }
        { resetStatus === ResetStatus.InvalidToken &&
          <Message negative>
            Your reset e-mail has expired, you have to <Link to="/request-password-reset">
              request a new one
            </Link>
          </Message>
        }
        { resetStatus === ResetStatus.ResetSuccessfull &&
          <Message positive>
            Your new password has been set successfully! You can now <Link to="/login">
              login with it.
            </Link>
          </Message>}
      </Segment>
    </Form>
  )
};


export default ResetSetPasswordForm;