import { observer } from "mobx-react";
import LoggedOutLayout from "../layouts/LoggedOutLayout";
import ResetPasswordForm from "../components/ResetPasswordForm";


const ResetPasswordPage = observer(() => {
  return (
    <LoggedOutLayout title="Set a new password">
      <ResetPasswordForm />
    </LoggedOutLayout>
  )
});

export default ResetPasswordPage;

