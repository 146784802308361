import { useRouteError } from "react-router-dom";


// TODO: we should make this prettier, but this only makes sense 
// in combination with general fixing error handling
interface RouteError {
  [key: string]: any,
}

export default function ErrorPage() {
  const error = useRouteError() as RouteError;

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
