import { useState } from "react";
import {
  Container,
  Grid,
  Menu,
  Header,
} from "semantic-ui-react";
import { observer } from "mobx-react";

import AccountComponent from "../components/AccountComponent";
import EditUsersComponent from "../components/OrganizationsComponent";

import UsersComponent from "../components/UsersComponent";

const UsersPage = observer(() => {
  const [activeItem, setActiveItem] = useState("my-account");

  return (
    <Container>
      <Header as="h1">Users</Header>
      <Grid>
        <Grid.Column width={4}>
          <Menu secondary vertical>
            <Menu.Item
              name="My Account"
              active={activeItem === "my-account"}
              onClick={() => setActiveItem("my-account")}
            />
            <Menu.Item
              name="Preferences"
              active={activeItem === "preferences"}
              onClick={() => setActiveItem("preferences")}
            />
            <Menu.Item
              name="Manage Users"
              active={activeItem === "manage-users"}
              onClick={() => setActiveItem("manage-users")}
            />
          </Menu>
        </Grid.Column>
        <Grid.Column width={10}>
          {activeItem === "my-account" && <AccountComponent />}
          {activeItem === "preferences" && <p>Preferences</p>}
          {activeItem === "manage-users" && <UsersComponent />}
          {activeItem === "manage-organizations" && <EditUsersComponent />}
        </Grid.Column>
      </Grid>
    </Container>
  );
});

export default UsersPage;
