import LoggedOutLayout from "../layouts/LoggedOutLayout";
import SignUpForm from "../components/SignUpForm";
import { Message } from "semantic-ui-react";
import { Link } from "react-router-dom";

const SignUpPage = () => {
  return (
    <LoggedOutLayout title="Sign-Up for an account">
      <SignUpForm/>
      <Message>
        Already have an account? <Link to="/login">Log-in</Link>
      </Message>
    </LoggedOutLayout>
  )
}

export default SignUpPage;
