import { useState } from "react";
import { Button, Form, Message, Segment } from "semantic-ui-react"
import authStore from "../stores/auth.store";
import PasswordWithConfirm from "./PasswordWithConfirm";


enum SignUpResult {
  NotRequested,
  UserAlreadyExists,
  EmailAlreadyExists,
  Successfull,
}
const SignUpForm = () => {
  const [ username, setUsername ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ signUpStatus, setSignUpStatus ] = useState(SignUpResult.NotRequested)
  const signUp = () => {
    authStore.signUp({
      username,
      email,
      password: password.trim(),
      confirm: password.trim(),
    }).then((_r) => {
      setSignUpStatus(SignUpResult.Successfull)
    }).catch((e) => {
      if (e.response.data.error?.msg) {
        const msg = e.response.data.error.msg;
        if ( msg === "Username already in use") {
          setSignUpStatus(SignUpResult.UserAlreadyExists)
        } else if ( msg === "Email already in use") {
          setSignUpStatus(SignUpResult.EmailAlreadyExists)
        }
      }
    })
  };
  return (
    <Form size="large" onSubmit={signUp} error>
      <Segment>
        <Form.Input
          label="Username"
          placeholder="example_user"
          iconPosition="left"
          icon="user"
          onChange={ (e) => setUsername(e.target.value) }
          required
          error={signUpStatus === SignUpResult.UserAlreadyExists && "User already exists, try a different name or login below"}
          fluid/>
        <Form.Input
          label="E-mail"
          placeholder="user@example.com"
          iconPosition="left"
          icon="mail"
          onChange={ (e) => setEmail(e.target.value) }
          required
          type="email"
          error={signUpStatus === SignUpResult.EmailAlreadyExists && "Email already exists, try a different one or login below"}
          fluid/>
        <PasswordWithConfirm onChange={ (password) => setPassword(password) }/>
        { signUpStatus !== SignUpResult.Successfull ?
          <Button positive fluid type="submit" size="large">
            Sign-up
          </Button> :
          <Message positive>
            You signed-up successfully and should get a verification email in the next minutes,
            make sure to also check your spam folder.
          </Message>
        } 
      </Segment>
    </Form>
  )
}

export default SignUpForm;
